<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> レポート - 月別優先度別営業報告件数</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                ></form-select>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <!-- 表出力 -->
            <div class="col-36 pt-5">
                <table v-if="sales_reports !== null" class="table table-bordered">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center"></th>
                                <template v-for="term_label in sales_reports.term_label" :key="term_label">
                                    <th class="text-center table-head">{{ term_label }}</th>
                                </template>
                            <th class="text-center table-head">合計</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="probability in sales_reports.probabilities" :key="probability.probability_name">
                            <tr>
                                <td class="align-middle px-4">{{ probability.probability_name }}</td>
                                <template v-for="(activity, index) in probability.activities" :key="index">
                                    <td class="align-middle px-4 text-end">{{ activity }}</td>
                                </template>
                                <td class="align-middle px-4 text-end">{{ probability.subtotal }}</td>
                            </tr>
                        </template>
                        <tr class="table-primary">
                            <td class="align-middle px-4">{{ sales_reports.total.probability_name }}</td>
                            <template v-for="(activity, index) in sales_reports.total.activities" :key="index">
                                <td class="align-middle px-4 text-end">{{ activity }}</td>
                            </template>
                            <td class="align-middle px-4 text-end">{{ sales_reports.total.subtotal }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </section>

</template>

<script>
import Department from '@/models/entities/department';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormSelect from '@/components/forms/FormSelect';

export default {
    name: 'ReportSaleReport',
    components: {
        InlineLoader,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,

            // 検索条件
            condition: {
                department_id: null,
            },

            // 表示データ
            sales_reports: null,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.startScreenLoading();
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.endScreenLoading();
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 月別優先度別営業報告件数の件数リスト取得
            this.fetchSalesReport();
        },
        // 月別優先度別営業報告件数の件数リスト取得
        fetchSalesReport() {
            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.sales_reports = null;

            this.$http.get('/report/sales-report', {
                params: this.condition,
            })
            .then(response => {
                this.sales_reports = response.data;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
    }
}
</script>

<style scoped>
.table-head {
    width: 65px;
}
</style>
